import React, { useState, useEffect } from 'react';
import { useRandomReveal } from 'react-random-reveal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import { useMediaQuery, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import {
  getDocs,
  collection,
  onSnapshot,
  getDoc,
  doc,
} from 'firebase/firestore';
import { firestore } from '../config/firebaseConfig';

import { colors } from '../styles';

dayjs.extend(utc);
dayjs.extend(tz);
const Home = () => {
  const theme = useTheme();
  const mq_xs = useMediaQuery(theme.breakpoints.only('xs'));
  const mq_sm = useMediaQuery(theme.breakpoints.only('sm'));
  const mq_md = useMediaQuery(theme.breakpoints.only('md'));
  const mq_lg = useMediaQuery(theme.breakpoints.only('lg'));
  const mq_xl = useMediaQuery(theme.breakpoints.only('xl'));

  const [prize, setPrize] = useState('');
  const [lottery, setLottery] = useState(null);
  const [lotteryData, setLotteryData] = useState(null);
  const [date, setDate] = useState(dayjs());

  const randomCharacters5 = useRandomReveal({
    isPlaying: true,
    // duration: 10,
    characters: '12345',
    characterSet: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    updateInterval: 0.2,
  });
  const randomCharacters4 = useRandomReveal({
    isPlaying: true,
    // duration: 10,
    characters: '1234',
    characterSet: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    updateInterval: 0.2,
  });
  const randomCharacters3 = useRandomReveal({
    isPlaying: true,
    // duration: 10,
    characters: '123',
    characterSet: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    updateInterval: 0.2,
  });
  const randomCharacters2 = useRandomReveal({
    isPlaying: true,
    // duration: 10,
    characters: '12',
    characterSet: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    updateInterval: 0.2,
  });

  const getLotteryData = async (currentDate) => {
    try {
      const docRef = doc(firestore, 'xsthm-vip', currentDate);
      const docSnap = await getDoc(docRef);

      if (docSnap.data() !== undefined) {
        // console.log(docSnap.data());
        setLotteryData(docSnap.data());
      } else {
        setLotteryData({
          prize1: { value: '0', activeStatus: false },

          prize2: { value: '0', activeStatus: false },

          prize3_1: { value: '0', activeStatus: false },
          prize3_2: { value: '0', activeStatus: false },

          prize4_1: { value: '0', activeStatus: false },
          prize4_2: { value: '0', activeStatus: false },
          prize4_3: { value: '0', activeStatus: false },
          prize4_4: { value: '0', activeStatus: false },
          prize4_5: { value: '0', activeStatus: false },
          prize4_6: { value: '0', activeStatus: false },

          prize5_1: { value: '0', activeStatus: false },
          prize5_2: { value: '0', activeStatus: false },
          prize5_3: { value: '0', activeStatus: false },
          prize5_4: { value: '0', activeStatus: false },

          prize6_1: { value: '0', activeStatus: false },
          prize6_2: { value: '0', activeStatus: false },
          prize6_3: { value: '0', activeStatus: false },
          prize6_4: { value: '0', activeStatus: false },
          prize6_5: { value: '0', activeStatus: false },
          prize6_6: { value: '0', activeStatus: false },

          prize7_1: { value: '0', activeStatus: false },
          prize7_2: { value: '0', activeStatus: false },
          prize7_3: { value: '0', activeStatus: false },

          prize8_1: { value: '0', activeStatus: false },
          prize8_2: { value: '0', activeStatus: false },
          prize8_3: { value: '0', activeStatus: false },
          prize8_4: { value: '0', activeStatus: false },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderDateTime = (time) => {
    const fireBaseTime = new Date(
      time.seconds * 1000 + time.nanoseconds / 1000000
    );
    const date = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();

    console.log(date);
    console.log(atTime);
  };

  const renderMenuBox = (topic, menu) => {
    return (
      <Box
        sx={{
          backgroundColor: colors.LightWhite,
          marginBottom: 1,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.MainColor,
            paddingX: 1,
            paddingY: 0.4,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <p
            style={{
              marginBottom: 0,
              color: colors.White,
              fontWeight: 'bold',
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            {topic}
          </p>
        </Box>
        <ul style={{ margin: 0, padding: '5px 25px' }}>
          {menu?.map((item, i) => {
            return (
              <li key={i} style={{ marginTop: 5, marginBottom: 5 }}>
                {item}
              </li>
            );
          })}
        </ul>
      </Box>
    );
  };

  const renderTopicAds = (topic, url) => {
    return (
      <Box
        sx={{
          backgroundColor: colors.Light,
          marginBottom: 1,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.MainColor,
            paddingX: 1,
            paddingY: 0.4,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <p
            style={{
              marginBottom: 0,
              color: colors.White,
              fontWeight: 'bold',
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            {topic}
          </p>
        </Box>
        <img alt="" src={url} style={{ width: '-webkit-fill-available' }} />
      </Box>
    );
  };

  const renderOnlyAds = (url) => {
    return (
      <Box
        sx={{
          backgroundColor: colors.Light,
          marginBottom: 1,
          borderRadius: 1,
        }}
      >
        <img alt="" src={url} style={{ width: '-webkit-fill-available' }} />
      </Box>
    );
  };

  const renderLoading = () => {
    // return <span className="loader"></span>;
    // return <CircularProgress />;
    return (
      <img
        width={'30px'}
        src={require('../assets/img/loading-orange.gif')}
        alt="loading..."
      />
    );
  };

  const renderIntervalNumber = (number) => {
    return (
      <span style={{ backgroundColor: 'yellow', color: '#CD1818' }}>
        {number === 5
          ? randomCharacters5
          : number === 4
          ? randomCharacters4
          : number === 3
          ? randomCharacters3
          : number === 2
          ? randomCharacters2
          : ''}
      </span>
    );
  };

  useEffect(() => {
    // const unsubscribe = onSnapshot(
    //   collection(firestore, 'lottery'),
    //   (snapshot) => {
    //     snapshot.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       console.log(doc.id, ' => ', doc.data());
    //       setLottery(doc.data());
    //     });
    //   },
    //   (error) => {
    //     // ...
    //     console.log(error);
    //   }
    // );
    const currentDateId = dayjs()
      .tz('Asia/Vientiane')
      .format('DD-MM-YYYY')
      .toString();

    const collectionRef = collection(firestore, 'xsthm-vip');
    const specificDocRef = doc(collectionRef, currentDateId);

    // Subscribe to real-time updates for the specific document
    const unsubscribe = onSnapshot(
      specificDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setLotteryData(data);
        } else {
          setLotteryData(null);
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    // To stop listening for updates (unsubscribe) when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const currentDate = dayjs(date)
      .tz('Asia/Vientiane')
      .format('DD-MM-YYYY')
      .toString();

    getLotteryData(currentDate);
  }, []);

  return (
    <Container sx={{ paddingY: 1 }}>
      {/* Header */}
      <Box>
        <Box>XSMB - SXMB - Kết quả xổ số miền Bắc - XSTD - KQXSMB - XSHN</Box>
        <Box>
          <div style={{ color: colors.MainColor, fontSize: 34 }}>XSTHM VIP</div>
        </Box>
      </Box>
      {/* Menu */}
      <Box sx={{ backgroundColor: colors.MainColor, marginTop: 1 }}>
        <nav
          className="navbar navbar-expand-lg navbar-dark bg-red"
          style={{ backgroundColor: colors.MainColor, padding: 0 }}
        >
          <div className="container-fluid">
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    XSMB
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    XSMN
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    XSMT
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    SỔ KẾT QUẢ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    VIETLOTT
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    DỰ ĐOÁN
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    QUAY THỬ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    THỐNG KÊ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    SỔ MƠ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Box>

      {/* Body */}
      <Stack
        direction="row"
        spacing={mq_md || mq_sm ? (mq_xs ? 2 : mq_sm ? 0 : 2) : mq_xs ? 0 : 2}
        sx={{ marginTop: 1 }}
        justifyContent={'space-between'}
      >
        {/* Left */}
        <Box
          width={'20%'}
          sx={{
            display: {
              xs: 'block',
              md: 'block',
              lg: 'block',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          {renderMenuBox('XỔ SỐ MIỀN BẮC', [
            'Hà Nội',
            'Quảng Ninh',
            'Bắc Ninh',
            'Hải Phòng',
            'Nam Định',
            'Thái Bình',
          ])}
          {renderMenuBox('XỔ SỐ ĐIỆN TOÁN', [
            'Điện toán 1*2*3, 6x36, thần tài',
            'Mega 6/45',
            'Max 3D',
            'Max 3D pro',
            'Power 6/55',
          ])}
          {renderMenuBox('XỔ SỐ MIỀN NAM', [
            'Cần Thơ',
            'Đồng Nai',
            'Sóc Trăng',
            'Cà Mau',
            'Đồng Tháp',
            'Bạc Liêu',
            'Bến Tre',
            'Vũng Tàu',
            'An Giang',
            'Bình Thuận',
            'Tây Ninh',
            'Bình Dương',
          ])}
        </Box>

        {/* Center */}
        <Box
          width={
            mq_md || mq_sm ? (mq_xs ? '80%' : '100%') : mq_xs ? '100%' : '52%'
          }
          bgcolor={colors.LightWhite}
          p={1}
        >
          <Box my={2}>xoso.me đang chuyển sang xambac</Box>
          <Box>
            hotSoi cầu ăn thônghotSoi cầu TOP 1hotCầu VIP ăn ngayhotCầu chuẩn 3
            miềnhotSố VIP ăn ngayhotMiền Bắc siêu chuẩnhotXSMNhotSXMThotXSMB
          </Box>

          <Box
            mt={2}
            sx={{
              backgroundColor: colors.MainColor,
              color: colors.White,
              textAlign: 'center',
              paddingY: 1,
              borderRadius: 1,
            }}
          >
            Xổ Số miền Bắc thêm:{' '}
            <span
              style={{ fontSize: 20, color: colors.Red, fontWeight: 'bold' }}
            >
              {dayjs(date).tz('Asia/Vientiane').format('DD-MM-YYYY').toString()}
            </span>
          </Box>

          <Box mt={2}>
            <table id="table-full">
              <tbody>
                <tr>
                  <td
                    style={{
                      color: colors.Blue,
                      fontWeight: 'bold',
                      fontSize: 15,
                    }}
                    colSpan={5}
                  >
                    <span>
                      {lotteryData?.loadingStatus === true
                        ? lotteryData?.prizeTop1?.activeStatus === false
                          ? lotteryData?.prize8_4?.activeStatus === true
                            ? '' // renderIntervalNumber(2)
                            : '' //renderLoading()
                          : lotteryData?.prizeTop1?.value === undefined
                          ? ''
                          : lotteryData?.prizeTop1?.value
                        : ''}
                    </span>
                    <span>
                      {lotteryData?.loadingStatus === true
                        ? lotteryData?.prizeTop2?.activeStatus === false
                          ? lotteryData?.prizeTop1?.activeStatus === true
                            ? '' // renderIntervalNumber(2)
                            : '' //renderLoading()
                          : lotteryData?.prizeTop2?.value === undefined
                          ? ''
                          : ' - ' + lotteryData?.prizeTop2?.value
                        : ''}
                    </span>
                    <span>
                      {lotteryData?.loadingStatus === true
                        ? lotteryData?.prizeTop3?.activeStatus === false
                          ? lotteryData?.prizeTop2?.activeStatus === true
                            ? '' // renderIntervalNumber(2)
                            : '' //renderLoading()
                          : lotteryData?.prizeTop3?.value === undefined
                          ? ''
                          : ' - ' + lotteryData?.prizeTop3?.value
                        : ''}
                    </span>
                    <span>
                      {lotteryData?.loadingStatus === true
                        ? lotteryData?.prizeTop4?.activeStatus === false
                          ? lotteryData?.prizeTop3?.activeStatus === true
                            ? '' // renderIntervalNumber(2)
                            : '' //renderLoading()
                          : lotteryData?.prizeTop4?.value === undefined
                          ? ''
                          : ' - ' + lotteryData?.prizeTop4?.value
                        : ''}
                    </span>
                    <span>
                      {lotteryData?.loadingStatus === true
                        ? lotteryData?.prizeTop5?.activeStatus === false
                          ? lotteryData?.prizeTop4?.activeStatus === true
                            ? '' // renderIntervalNumber(2)
                            : '' //renderLoading()
                          : lotteryData?.prizeTop5?.value === undefined
                          ? ''
                          : ' - ' + lotteryData?.prizeTop5?.value
                        : ''}
                    </span>
                    <span>
                      {lotteryData?.loadingStatus === true
                        ? lotteryData?.prizeTop6?.activeStatus === false
                          ? lotteryData?.prizeTop5?.activeStatus === true
                            ? '' // renderIntervalNumber(2)
                            : '' //renderLoading()
                          : lotteryData?.prizeTop6?.value === undefined
                          ? ''
                          : ' - ' + lotteryData?.prizeTop6?.value
                        : ''}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>ĐB</td>
                  <td className="result-number-big" colSpan={4}>
                    <Grid container>
                      <Grid item xs={12} className="content-number-center">
                        {/* {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize1?.numberLoading === true
                            ? renderIntervalNumber(5)
                            : lotteryData?.prize1?.activeStatus === false
                            ? renderLoading()
                            : lotteryData?.prize1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize1?.value
                          : ''} */}
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize1?.activeStatus === false
                            ? lotteryData?.prize8_4?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize1?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>Giải 1</td>
                  <td className="result-number-black" colSpan={4}>
                    <Grid container>
                      <Grid item xs={12} className="content-number-center">
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize2?.numberLoading === true
                            ? renderIntervalNumber(5)
                            : lotteryData?.prize2?.activeStatus === false
                            ? renderLoading()
                            : lotteryData?.prize2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize2?.value
                          : ''}
                      </Grid>
                    </Grid>

                    {/* template */}
                    {/* template */}
                    {/* template */}
                    {/* template */}
                    {/* template */}
                    {/* <Grid container>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        <span>xs=6</span>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        <span>xs=6</span>
                      </Grid>
                      <Grid item xs={4} className="content-number-center result-number-black">
                        <span>xs=6</span>
                      </Grid>
                    </Grid> */}
                  </td>
                </tr>
                <tr style={{ backgroundColor: colors.White }}>
                  <td style={{ width: 100 }}>Giải 2</td>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize3_1?.activeStatus === false
                            ? lotteryData?.prize2?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize3_1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize3_1?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize3_2?.activeStatus === false
                            ? lotteryData?.prize3_1?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize3_2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize3_2?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>

            <table id="table-no-tb">
              <tbody>
                <tr>
                  <td style={{ width: 100 }} rowSpan={2}>
                    Giải 3
                  </td>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize4_1?.activeStatus === false
                            ? lotteryData?.prize3_2?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize4_1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize4_1?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize4_2?.activeStatus === false
                            ? lotteryData?.prize4_1?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize4_2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize4_2?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize4_3?.activeStatus === false
                            ? lotteryData?.prize4_2?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize4_3?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize4_3?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr style={{ borderTop: '1px solid #d3d3d3' }}>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize4_4?.activeStatus === false
                            ? lotteryData?.prize4_3?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize4_4?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize4_4?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize4_5?.activeStatus === false
                            ? lotteryData?.prize4_4?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize4_5?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize4_5?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize4_6?.activeStatus === false
                            ? lotteryData?.prize4_5?.activeStatus === true
                              ? renderIntervalNumber(5)
                              : renderLoading()
                            : lotteryData?.prize4_6?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize4_6?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>

            <table style={{ backgroundColor: colors.White }} id="table-full">
              <tbody>
                <tr>
                  <td style={{ width: 100 }}>Giải 4</td>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize5_1?.activeStatus === false
                            ? lotteryData?.prize4_6?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize5_1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize5_1?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize5_2?.activeStatus === false
                            ? lotteryData?.prize5_1?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize5_2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize5_2?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize5_3?.activeStatus === false
                            ? lotteryData?.prize5_2?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize5_3?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize5_3?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize5_4?.activeStatus === false
                            ? lotteryData?.prize5_3?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize5_4?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize5_4?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>

            <table id="table-no-tb">
              <tbody>
                <tr>
                  <td style={{ width: 100 }} rowSpan={2}>
                    Giải 5
                  </td>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize6_1?.activeStatus === false
                            ? lotteryData?.prize5_4?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize6_1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize6_1?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize6_2?.activeStatus === false
                            ? lotteryData?.prize6_1?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize6_2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize6_2?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize6_3?.activeStatus === false
                            ? lotteryData?.prize6_2?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize6_3?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize6_3?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
                <tr style={{ borderTop: '1px solid #d3d3d3' }}>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize6_4?.activeStatus === false
                            ? lotteryData?.prize6_3?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize6_4?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize6_4?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize6_5?.activeStatus === false
                            ? lotteryData?.prize6_4?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize6_5?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize6_5?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize6_6?.activeStatus === false
                            ? lotteryData?.prize6_5?.activeStatus === true
                              ? renderIntervalNumber(4)
                              : renderLoading()
                            : lotteryData?.prize6_6?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize6_6?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>

            <table style={{ backgroundColor: colors.White }} id="table-no-tb">
              <tbody>
                <tr style={{ borderTop: '1px solid #d3d3d3' }}>
                  <td style={{ width: 100 }}>Giải 6</td>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize7_1?.activeStatus === false
                            ? lotteryData?.prize6_6?.activeStatus === true
                              ? renderIntervalNumber(3)
                              : renderLoading()
                            : lotteryData?.prize7_1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize7_1?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="border-tb-right content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize7_2?.activeStatus === false
                            ? lotteryData?.prize7_1?.activeStatus === true
                              ? renderIntervalNumber(3)
                              : renderLoading()
                            : lotteryData?.prize7_2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize7_2?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="content-number-center result-number-black"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize7_3?.activeStatus === false
                            ? lotteryData?.prize7_2?.activeStatus === true
                              ? renderIntervalNumber(3)
                              : renderLoading()
                            : lotteryData?.prize7_3?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize7_3?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>

            <table id="table-full">
              <tbody>
                <tr>
                  <td style={{ width: 100 }}>Giải 7</td>
                  <td>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        className="border-tb-right content-number-center text-red"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize8_1?.activeStatus === false
                            ? lotteryData?.prize7_3?.activeStatus === true
                              ? renderIntervalNumber(2)
                              : renderLoading()
                            : lotteryData?.prize8_1?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize8_1?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className="border-tb-right content-number-center text-red"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize8_2?.activeStatus === false
                            ? lotteryData?.prize8_1?.activeStatus === true
                              ? renderIntervalNumber(2)
                              : renderLoading()
                            : lotteryData?.prize8_2?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize8_2?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className="border-tb-right content-number-center text-red"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize8_3?.activeStatus === false
                            ? lotteryData?.prize8_2?.activeStatus === true
                              ? renderIntervalNumber(2)
                              : renderLoading()
                            : lotteryData?.prize8_3?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize8_3?.value
                          : ''}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className="content-number-center text-red"
                      >
                        {lotteryData?.loadingStatus === true
                          ? lotteryData?.prize8_4?.activeStatus === false
                            ? lotteryData?.prize8_3?.activeStatus === true
                              ? renderIntervalNumber(2)
                              : renderLoading()
                            : lotteryData?.prize8_4?.value === undefined
                            ? renderLoading()
                            : lotteryData?.prize8_4?.value
                          : ''}
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>

          <Box mt={2}>
            {renderMenuBox('Dự đoán xổ số miền Bắc hôm nay', [
              'Xem thêm thống kê lô gan miền Bắc',
              'Xem thêm thống kê giải đặc biệt miền Bắc',
              'Xem cao thủ dự đoán miền Bắc hôm nay chính xác nhất',
              'Tham gia quay thử miền Bắc để thử vận may',
              'Hãy soi cầu bạch thủ miền Bắc để chọn bộ số ngon nhất',
            ])}
          </Box>
        </Box>

        {/* Right */}
        <Box
          width={'28%'}
          sx={{
            display: {
              xs: 'block',
              md: 'none',
              lg: 'block',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          {renderTopicAds(
            'hot BÌNH CHỌN CẶP SỐ ĐẸP',
            'https://cdn.xoso.mobi/images/ngocheme2_300x100.gif'
          )}
          {renderMenuBox('DỰ ĐOÁN CÁC TỈNH HÔM NAY', [
            'Dự đoán Đồng Nai',
            'Dự đoán Cần Thơ',
            'Dự đoán Sóc Trăng',
            'Dự đoán Đà Nẵng',
            'Dự đoán Khánh Hòa',
          ])}
          {renderOnlyAds('https://cdn.xoso.mobi/images/300x100_soicau888.gif')}
          {renderMenuBox('KẾT QUẢ XỔ SỐ HÔM QUA', [
            'XmdN hôm qua',
            'XmdB hôm qua',
            'XmdT hôm qua',
          ])}
        </Box>
      </Stack>
    </Container>
  );
};

export default Home;
